@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */
:root {
  --header-height: 80px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #fff;
}

.app {
  @apply text-black font-inter flex flex-col min-h-screen;
}

h1 {
  @apply text-4xl font-extrabold;
}

h2 {
  @apply text-base sm:text-3xl font-bold;
}

h3 {
  @apply text-base sm:text-2xl font-semibold;
}

h4 {
  @apply text-lg font-semibold;
}

h6 {
  @apply text-base font-normal;
}

p {
  @apply text-sm font-normal leading-relaxed text-black;
}

header {
  @apply bg-SECONDARY backdrop-blur-sm dark:bg-gray-900/70 dark:border-gray-700 xl:fixed xl:w-full xl:top-0 xl:left-0 xl:z-30 h-[var(--header-height)];
  /* border-radius: 0px 0px 1.5rem 1.5rem; */
}

main {
  @apply flex-1 pb-8 xl:mt-[var(--header-height)] md:min-h-[80vh];
}

.page-container {
  @apply max-w-2xl px-4 sm:px-6 py-6 mx-auto lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl;
}

footer {
  @apply p-4;
}

.error-text {
  @apply flex justify-center items-center mt-20 text-red-500;
}

.empty-text {
  @apply flex justify-center items-center mt-20;
}
